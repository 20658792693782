import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'inherit',
  },
  palette: {
    primary: { main: '#4090f1' },
    secondary: { main: '#7d76d6' },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
})
