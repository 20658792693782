import React from 'react'
import { Helmet } from 'react-helmet'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'Config/snackbars'
import { theme } from 'Config/mui'
import { ThemeProvider } from '@material-ui/core/styles'
import { RECAPTCHA_KEY } from 'Utils/network/recaptcha'

export type TopLayoutProps = {
  children: React.ReactNode
}

function TopLayout(props: TopLayoutProps) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#df494b" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#df494b" />
        <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`}
        ></script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <SnackbarUtilsConfigurator />
          {props.children}
        </SnackbarProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default TopLayout
