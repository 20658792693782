// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-outsourcing-tsx": () => import("./../../../src/pages/outsourcing.tsx" /* webpackChunkName: "component---src-pages-outsourcing-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-delaycalc-tsx": () => import("./../../../src/pages/privacy-delaycalc.tsx" /* webpackChunkName: "component---src-pages-privacy-delaycalc-tsx" */),
  "component---src-pages-privacy-migraine-tsx": () => import("./../../../src/pages/privacy-migraine.tsx" /* webpackChunkName: "component---src-pages-privacy-migraine-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

