export const RECAPTCHA_KEY = '6Le4ZzcaAAAAALRJOdGacj7vsaKOQ_1BNLB3nMHN'

declare global {
  interface Window {
    captchaOnLoad: () => void
    grecaptcha: ReCaptchaInstance
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => any
  execute: (key: string, params: ReCaptchaExecuteOptions) => Promise<string>
  render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: 'invisible'
}

export const withRecaptcha = () => {
  return new Promise<string>((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(RECAPTCHA_KEY, { action: 'submit' })
        .then((token: string) => {
          resolve(token)
          return null
        })
        .catch((error: any) => {
          console.error('withRecaptcha Error:', error)
          reject(error)
        })
    })
  })
}
