/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { get } from 'lodash'
import 'Styles/index.scss'
import 'swiper/swiper-bundle.min.css'
import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos.js'

AOS.init({
  offset: 0,
  duration: 400,
  easing: 'ease-out-cubic',
  delay: 100,
  once: true,
})

export const shouldUpdateScroll = ({ prevRouterProps, routerProps, pathname }) => {
  // Помогает избежать скролла к началу страницы при изменении GET-параметров
  const prevPathname = get(prevRouterProps, ['location', 'pathname'])
  const prevSearch = get(prevRouterProps, ['location', 'search'])
  const newSearch = get(routerProps, ['location', 'search'])

  if (pathname === '/' && prevSearch === newSearch) {
    return true
  }

  return prevPathname !== pathname
}
