import { OptionsObject, SnackbarMessage, useSnackbar, WithSnackbarProps } from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps

const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp
}

type InnerProps = {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

const InnerSnackbarUtilsConfigurator = (props: InnerProps) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

// Allows to use Snackbars outside of components
export const Snackbars = {
  enqueue(message: SnackbarMessage, options?: OptionsObject) {
    useSnackbarRef.enqueueSnackbar(message, options)
  },
  close(key: string) {
    useSnackbarRef.closeSnackbar(key)
  },
}
